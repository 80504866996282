<template>
  <div class="arrow-down" :class=" props.isUp ? 'blinking-up' : 'blinking-down' ">
    <img v-if="props.isUp" src="@/assets/images/arrow-up.png" style="width: 20px;">
    <img v-else src="@/assets/images/arrow-down.png" style="width: 20px;">
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  isUp: { type: Boolean, default: false },
});

</script>

<style lang="scss" scoped>
.arrow-down{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}
@keyframes blink-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
}
 
.blinking-down {
  animation: blink-down 1s infinite;
}
@keyframes blink-up {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}
 
.blinking-up {
  animation: blink-up 1s infinite;
}

</style>