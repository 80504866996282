<template>
  <div class="btn-box">
    <div class="text">{{ props.text }}</div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue'

const props = defineProps({
  icon: { type: String, default: '' },
  text: { type: String, default: '' },
});

</script>

<style lang="scss" scoped>
.btn-box {
  width: 100%;
  height: 46px;
  background: url('@/assets/images/button-home.png') 0 0 no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  .text{
    color: #FFF;
    text-align: center;
    font-family: Orbitron;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0 5px;
  }
  img{
    width: 12px;
    margin: 0 5px;
  }
}

</style>