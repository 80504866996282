<template>
  <div class="logo-bar">
    <!-- <img src="@/assets/images/home-top1.png" style="width: 110px;">
    <img src="@/assets/images/home-top2.png" style="width: 12px;margin: 0 10px;">
    <img src="@/assets/images/home-top3.png" style="width: 48px;">
    <img src="@/assets/images/home-top4.png" style="width: 78px;"> -->

    <img src="@/assets/images/logo.png" style="width: 70%;">
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>
.logo-bar{
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>