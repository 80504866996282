<template>
  <div class="page">
    <!-- <Header :progress-show="false" />-->

    <div class="top-bar">
      <LogoBar />
    </div>

    <div class="bottom-bar">
      <div class="orbitron-font main-title">
        G<span>AI</span>A
        <br>
        Discord Role Test
      </div>

      <div class="main-content">
        GET SORTED INTO
        <br>
        YOUR DESTINED DISCORD ROLE
      </div>

      <ArrowDownUp />

      <div class="btns-box">
        <el-row :gutter="0">
          <el-col :span="6"><div class="grid-content" /></el-col>
          <el-col :span="12"><div class="grid-content" />
            <ButtonHome text="" @click="onStart"></ButtonHome>
          </el-col>
          <el-col :span="6"><div class="grid-content" /></el-col>
        </el-row>
      </div>


    </div>

    

  </div>
</template>

<script setup lang="ts">
import { useRouter } from "vue-router";
import LogoBar from '@/components/LogoBarComponent.vue'
import ArrowDownUp from '@/components/ArrowDownUp.vue'
import ButtonHome from '@/components/ButtonHomeComponent.vue'

const router = useRouter();

const onStart = () => {
  router.replace({ path: '/process' })
}
</script>

<style lang="scss" scoped>
.page{
  width: 100%;
  height: 100%;
  background: url('@/assets/images/bg-home.png') 0 0 no-repeat;
  // background-color: #0E141E;
  background-size: 100% 100%;
  position: relative;
  .top-bar{
    width: 100%;
    height: 114px;
    background: linear-gradient(180deg, #0E141E 0%, rgba(14, 20, 30, 0.00) 100%);
    padding-top: 10px;
    box-sizing: border-box;
  }
  .bottom-bar{
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 100px;
    .main-title{
      width: 100%;
      color: #FFF;
      text-align: center;
      font-size: 54px;
      font-style: normal;
      font-weight: 800;
      line-height: 1.2;
      letter-spacing: 1.08px;
      span{
        color: #D43327;
      }
    }
    .main-content{
      width: 100%;
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 1.6;
      letter-spacing: 0.64px;
      text-transform: uppercase;
    }

    .btns-box{
      width: 100%;
    }
    
    
  }
  
  .welcome{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0;
    img{
      width: 80%;
      margin: 0 auto;
    }
  }
  .description{
    width: 100%;
    font-size: 18px;
    line-height: 1.6;
    color: #333333;
    padding: 0 30px;
    box-sizing: border-box;
    text-align: center;
    p{
      font-size: 20px;
      font-weight: bold;
      padding: 20px 0;
    }
  }
  
}
</style>