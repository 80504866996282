<template>
  <router-view />
</template>

<script setup lang="ts">
</script>

<style>
@import "./assets/css/index.css";
</style>
